//const BaseURL = 'http://24e-melian-v2-backend.sitios.yt/api';
//const BaseURL = 'http://melian-v2-backend-24e.sitios.yt/api';
//const BaseURL = 'http://melian-v2-backend.sitios.yt/api';
//const BaseURL = 'http://192.168.1.151/api';
/*export { 
  BaseURL,
}
*/

const BaseURL = 'https://plei-end.melian.me/api';

const sitekey ="6LdChZwUAAAAAIh32DATM4AHCVIxuEqEygIhrYVV";
const multicurso = false;
const paidMode = false;
const userCanRegister = false;


const logos = {
  logoTopbar: 'logo_plei.png',
  logoLogin: 'logo_plei.png',
  backgroundLogin: 'background_desktop.jpg',
  logo_inicio_courses: 'logo_plei.png',
}
const textos = {
  textoLogoLogin:'',
  bienvenido: 'Bienvenido/a',
  tituloDashboard: "Plei",
  textoDashboard: `El programa Mujeres 360 desarrolla habilidades en tecnología digital enfocada en los empleos del futuro
  y el desarrollo de habilidades blandas con el objetivo e independencia económica de mujeres jóvenes Utilizamos la tecnologías
  digitales no como objetivo sino como un instrumento y catalizador para ayudar a reducir las desigualdades.`,
  bienvenidaRegistro: `¡Bienvenid@ a PLEI!`,
}

const auxpiciadores = [
  {
    logo: 'logo_GCL.png',  
    width: 85,
    height: 'auto',
    colSize: 12,
  },
  {
    logo: 'logo_CNC.png',  
    width: 123,
    height: 'auto',
    colSize: 12,
  },
  {
    logo: 'logo_dhl.png',  
    width: 139,
    height: 'auto',
    colSize: 12,
  },
  {
    logo: 'logo_eressea.png',  
    width: 124,
    height: 'auto',
    colSize: 12,
  },
]

const logosLogin = [
  {
    logo: 'logo_efut.png',  
    width: 159,
    height: 48
  },
  {
    logo: 'logo_be.png',  
    width: 166,
    height: 48
  }
]

export {
  BaseURL,
  logos,
  textos,
  auxpiciadores,
  logosLogin,
  sitekey,
  multicurso,
  paidMode,
  userCanRegister
}
