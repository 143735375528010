const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  REGISTER: 'REGISTER',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (userInfo) => ({
    type: actions.LOGIN_REQUEST,
    payload: { userInfo },
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  loginSuccess: payload => ({
    type: actions.LOGIN_SUCCESS,
    idToken: payload.idToken,
    userName: payload.userName,
    profilePicture: payload.profilePicture,
    lessons: payload.lessons,
  }),
};
export default actions;
