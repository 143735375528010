let colorsMain ={
  color0:'transparent',
  color1:"#ffffff",     
  color2:"#000000",
  color3:"#ff961a",
  color4:"#121c2d",
  color5:'#000e1e',
  color6:'#000e1e',
  color7:'#000e1e',
  color8:'#e5e5e5',
  color9:'#064059',
  color10:'#999999',
  color11:'#333333',
  color12:'#1D334D',
  color13:'#ff961a',   //colores diferente para 24e y m360
  color14:'rgba(255,255,255,0.4)',
  color15:'rgba(2,1,12,0.4)',
}

export {
  colorsMain
}