import actions from './actions';

const initState = { 
  idToken: null,
  userName: null,
  profilePicture: null,
  lessons: [],
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        userName: action.userName,
        profilePicture: action.profilePicture,
        lessons: action.lessons,
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
