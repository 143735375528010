import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import JwtAuthentication from '@iso/lib/jwt-auth/jwtAuthentication';

import { getUserData, clearUserData, setUserData } from '@iso/lib/helpers/utility';
import actions from './actions';
import notification from '@iso/components/Notification';
import {userCanRegister} from "../../config/config";

const history = createBrowserHistory();

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    const result = yield call(JwtAuthentication.login, payload.userInfo);
    if (result.success) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: result.token,
        profile: 'Profile',
        userName: result.userName?result.userName:'',
        profilePicture: result.profilePicture?result.profilePicture:'',
        lessons: result.lessons?result.lessons:[],
      });
    } else {
      notification('error', result.error?result.error:'Sorry, there was an error with your request');
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield setUserData(
      payload.token,
      payload.userName,
      payload.profilePicture, 
      payload.lessons);
  });
}
export function* register() {
  yield takeEvery(actions.REGISTER, function*() {
    let path = window.location.href
    if(!path.includes('register') || !userCanRegister){
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {
    yield clearUserData();
    history.push('/');
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield call(JwtAuthentication.logout, {});
    yield clearUserData();
    history.push('/');
    window.location.reload()
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getUserData().get('idToken');
    const userName = getUserData().get('userName');
    const profilePicture = getUserData().get('profilePicture');
    const lessons = getUserData().get('lessons');
    let path = window.location.href
    localStorage.setItem('redirectURL', path);
    if (token && token !=='undefined') {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        userName,
        profilePicture,
        lessons,
      });
    } else {
      let path = window.location.href
      if(!path.includes('signin?code=')){
        yield put({ type: actions.REGISTER });
      }
    }
  });
}

/*export function* selectedCourseSuccess() {
  yield takeEvery(actions.SELECTED_COURSE_SUCCESS, function*(payload) {
    const token = getUserData().get('idToken');
    const userName = getUserData().get('userName');
    const profilePicture = getUserData().get('profilePicture');
    const lessons = getUserData().get('lessons');
    const selectedCourse = payload.selectedCourse
    yield setUserData(
      token,
      userName,
      profilePicture, 
      lessons,
      selectedCourse
    );
    yield history.push('/dashboard/Curso/' + selectedCourse);
  })
}*/

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(register),
    //fork(selectedCourseSuccess),
  ]);
}
